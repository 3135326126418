<template>
  <div class="login-container">
    <div class="login-content">
        <h2>Recuperar senha</h2>
        <form class="login-content-form">
            <r-input v-model="form.email" label="Digite seu email" type="text" />
            <button
            @click="request()"
            type="button"
            class="button_outline"
            style="width:100%; color: white; background:#14a949; margin-top:40px"
            :disabled="loading"
        >
            Redefinir senha
        </button>
        </form>
        <div style="margin-top:20px">
            <p>Já possui uma conta? <router-link class="link" to="/auth/login" style="text-decoration:none; color:#14a949"><strong>Entrar</strong></router-link></p>
        </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import swal from 'sweetalert'
import { mapActions } from 'vuex'

export default Vue.extend({
  data: () => ({
    form: { email: '' },
    loading: false
  }),
  components: {
    RInput: () => import('@/components/input.vue')
  },
  methods: {
    ...mapActions('user', ['login']),
    async request () {
      this.loading = true
      await this.$http.post('/auth/recover', this.form).then(resposta => {
        swal({
          title: 'Acabamos de enviar um email para você',
          text: 'Siga as instruções do email para redefinir sua senha!',
          icon: 'success',
          button: 'Ok'
        })
        this.$router.push('/auth/reset')
      }).catch(
        swal({
          title: 'Ooops!',
          text: 'Confira o email digitado e tente novamente.',
          icon: 'error',
          button: 'Ok'
        })
      )
      this.loading = false
    }
  }
})
</script>

<style scoped>
.login-container {
  background: #ffffff !important;
  width: 600px;
  min-height: 400px;
  max-width: 98%;
  border-radius: 0.75rem;
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.15);
  padding: 1.5rem;
  margin: 2rem auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  position: relative;
}

.login-content,
.login-image {
  display: block;
}

.login-content {
  width: 500px;
  max-width: 100%;
}

.login-content h2,
.login-content h3 {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 3rem;
  width: 100%;
}
.login-content h3 {
  text-align: left;
  margin-bottom: 2rem;
  color: #14a949;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 0.5rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.login-content-form {
  width: 100%;
  max-width: 96%;
  margin: 0 auto;
  padding: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0.8rem;
}
</style>
